import React, { useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SharerIcon from '../../assets/svg/sharer.inline.svg'
import FacebookIcon from '../../assets/svg/facebook.inline.svg'
import TwitterIcon from '../../assets/svg/twitter.inline.svg'
import LinkedInIcon from '../../assets/svg/linkedin.inline.svg'
import VkIcon from '../../assets/svg/vk.inline.svg'

import * as styles from './sharer.module.scss'

const SocialSharer = props => {
    const pageGeneralSettings = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `
    )
    const [copySuccess, setCopySuccess] = useState('')
    const inputAreaRef = useRef(null)
    const pageLink = pageGeneralSettings.site.siteMetadata.siteUrl
    const postLink = pageLink + props.postUri

    const copyToClipboard = e => {
        e.preventDefault()

        inputAreaRef.current.select()
        typeof document !== 'undefined' && document.execCommand('copy')
        setCopySuccess('Copied!')
    }

    return (
        <section className={styles.wrapper}>
            <h2 className='visually-hidden'>Share this article</h2>
            <ul className={styles.list}>
                {typeof document !== 'undefined' && document.queryCommandSupported('copy') && (
                    <li
                        data-sal='slide-up'
                        data-sal-delay='150'
                        data-sal-duration='1800'
                        data-sal-easing='ease-out-back'
                    >
                        <a href='#' rel='noopener noreferrer' onClick={copyToClipboard}>
                            <SharerIcon alt='link-icon' />
                            <span className='visually-hidden'>Copy link</span>
                        </a>
                        <label className='visually-hidden'>
                            Readonly field for 'Copy link' function
                            <input
                                readOnly='readonly'
                                tabIndex='-1'
                                ref={inputAreaRef}
                                value={typeof window !== 'undefined' && window.location.href}
                            />
                        </label>
                        <span className={`${styles.message} ${copySuccess ? styles.messageShow : ''}`}>
                            {copySuccess && copySuccess + ' 👍'}
                        </span>
                    </li>
                )}
                <li data-sal='slide-up' data-sal-delay='250' data-sal-duration='1800' data-sal-easing='ease-out-back'>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${postLink}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FacebookIcon />
                        <span className='visually-hidden'>Facebook</span>
                    </a>
                </li>
                <li data-sal='slide-up' data-sal-delay='450' data-sal-duration='1800' data-sal-easing='ease-out-back'>
                    <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${postLink}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <LinkedInIcon />
                        <span className='visually-hidden'>LinkedIn</span>
                    </a>
                </li>
                <li data-sal='slide-up' data-sal-delay='350' data-sal-duration='1800' data-sal-easing='ease-out-back'>
                    <a
                        href={`https://twitter.com/intent/tweet?text=I%20just%20finished%20reading%20this%20awesome%20article%20on%20@endeavor_hub%E2%80%99s%20blog!%20${postLink}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <TwitterIcon />
                        <span className='visually-hidden'>Twitter</span>
                    </a>
                </li>
                <li data-sal='slide-up' data-sal-delay='550' data-sal-duration='1800' data-sal-easing='ease-out-back'>
                    <a href={`https://vk.com/share.php?url=${postLink}`} target='_blank' rel='noopener noreferrer'>
                        <VkIcon />
                        <span className='visually-hidden'>VK</span>
                    </a>
                </li>
            </ul>
        </section>
    )
}

export default SocialSharer
