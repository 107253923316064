import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Grid, Carousel } from 'antd'
import loadable from '@loadable/component'

import * as styles from './recent.module.scss'

const RecentPost = loadable(() => import('./RecentPost'))

const RecentPosts = ({ title, list, link, withBackground }) => {
    const { allWpPost } = useStaticQuery(graphql`
        query lastPosts {
            allWpPost(limit: 3, filter: { status: { eq: "publish" } }, sort: { fields: date, order: DESC }) {
                nodes {
                    id
                    status
                    title
                    uri
                    date(formatString: "DD.MM.YYYY")
                    featuredImage {
                        node {
                            altText
                            localFile {
                                ...PostThumbnail
                            }
                        }
                    }
                }
            }
        }
    `)
    const { useBreakpoint } = Grid
    const { sm } = useBreakpoint()
    const posts = list && list.length > 0 ? list : allWpPost.nodes

    return (
        <div className={withBackground ? styles.colorWrapper : styles.wrapper}>
            {posts.length > 0 && (
                <div className={withBackground ? 'global-container' : ''}>
                    <h2
                        className='global-title-h2'
                        data-sal='slide-up'
                        data-sal-delay='250'
                        data-sal-duration='1500'
                        data-sal-easing='ease-out-back'
                    >
                        {title ? title : 'News'}
                    </h2>
                    {!sm ? (
                        <Carousel
                            className={styles.slider + ' global-full-width'}
                            draggable={true}
                            slidesToScroll={1}
                            variableWidth={true}
                            easing='cubic-bezier(0.65, 0.05, 0.36, 1)'
                        >
                            {posts.map((post, i) => {
                                return <RecentPost key={post.id} post={post} index={i} />
                            })}
                        </Carousel>
                    ) : (
                        <div className={styles.list}>
                            {posts.map((post, i) => {
                                return <RecentPost key={post.id} post={post} index={i} />
                            })}
                        </div>
                    )}
                    <Link
                        to={link && link.url ? link.url : '/blog'}
                        target={link && link.target ? link.target : ''}
                        className='global-button global-button--rounded'
                        data-sal='slide-up'
                        data-sal-delay='350'
                        data-sal-duration='1500'
                        data-sal-easing='ease-out-back'
                    >
                        {link && link.title ? link.title : 'See more'}
                    </Link>
                </div>
            )}
        </div>
    )
}

export default RecentPosts
